<template>
  <v-container fluid class="pa-0">

    <!-- App bar -->
    <v-app-bar height="30" dark color="primary" flat>
      <div class="mx-auto" style="font-size: 20px">Mnemonist Software</div>
    </v-app-bar>

    <!-- Header -->
    <Header :title="$t('events.sequence')" :level="level" :hearts="hearts"/>

    <!-- Game -->
    <div id="game">
      <div class="row" v-for="(row, rowIndex) in rows" :key="rowIndex">
        <div class="tile" v-for="(tile, tileIndex) in row" :key="tileIndex"
             :style="{'background-color': tile, 'width': scaledTileSize + 'px', 'height': scaledTileSize + 'px'}"
             @click="onTile(rowIndex * rowSize + tileIndex)">
        </div>
      </div>
    </div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderWarmUp';

export default {
  name: 'Sequence',
  components: {Header},
  data: () => ({
    sequence: [], input: [], level: 1, hearts: 3,
    tileSize: 150, rowSize: 3, lock: false, phase: 'wait',
    rows: [['#fff', '#fff', '#fff'], ['#fff', '#fff', '#fff'], ['#fff', '#fff', '#fff']]
  }),
  computed: {
    scaledTileSize() {
      return this.tileSize * (this.$vuetify.breakpoint.width > 530 ? 1 : 0.7);
    }
  },
  mounted() {
    this.loadLevel();
  },
  methods: {
    calculateRows() {
      let rows = [];
      for (let i = 0; i < this.rowSize; i++) rows.push(new Array(this.rowSize).fill('#ffffff'));
      if (this.phase === 'watch') {
        for (let i = 0; i < this.rowSize; i++) for (let j = 0; j < this.rowSize; j++)
          if (this.sequence[i * this.rowSize + j]) rows[i][j] = '#336799';
      } else if (this.phase === 'input') {
        for (let i = 0; i < this.rowSize; i++) for (let j = 0; j < this.rowSize; j++) {
          if (this.input[i * this.rowSize + j] && this.sequence[i * this.rowSize + j]) rows[i][j] = '#336799';
          else if (this.input[i * this.rowSize + j]) rows[i][j] = '#e53935';
        }
      }
      this.rows = rows;
    },
    generateSequence() {
      let values = new Array(this.rowSize * this.rowSize);
      for (let i = 0; i < values.length; i++) values[i] = i;
      values = values.map((v) => ({v, s: Math.random()})).sort((a, b) => a.s - b.s).map((v) => v.v);
      let sequence = new Array(this.rowSize * this.rowSize).fill(false);
      for (let i = 0; i < this.level + 2; i++) sequence[values[i]] = true;
      return sequence;
    },
    getRowSize() {
      if (this.level < 3) return 3;
      if (this.level < 6) return 4;
      if (this.level < 9) return 5;
      if (this.level < 14) return 6;
      return 7;
    },
    loadLevel() {
      this.lock = true;
      this.phase = 'wait';
      this.rowSize = this.getRowSize();
      this.tileSize = Math.floor(500 / this.rowSize) - 16;
      this.input = new Array(this.rowSize * this.rowSize).fill(false);
      this.sequence = this.generateSequence();
      this.calculateRows();
      setTimeout(() => {
        this.phase = 'watch';
        this.calculateRows();
        setTimeout(() => {
          this.phase = 'input';
          this.calculateRows();
          this.lock = false;
        }, 1500);
      }, 1000);
    },
    onFail() {
      this.lock = true;
      this.hearts = this.hearts - 1;
      this.calculateRows();

      if (this.hearts === 0) return this.showResult();

      setTimeout(() => {
        this.phase = 'wait';
        this.calculateRows();
        setTimeout(() => this.loadLevel(), 1000);
      }, 1000);
    },
    onSuccess() {
      this.lock = true;
      this.level = this.level + 1;
      this.calculateRows();

      if (this.level === 47) return this.showResult();

      setTimeout(() => {
        this.phase = 'wait';
        this.calculateRows();
        setTimeout(() => this.loadLevel(), 1000)
      }, 1000);
    },
    onTile(id) {
      if (this.lock) return;
      this.input[id] = true;
      if (this.input.filter((v, i) => v && !this.sequence[i]).length >= 3)
        return this.onFail();
      else if (this.sequence.filter((v, i) => v && this.input[i]).length === this.level + 2)
        return this.onSuccess();
      else this.calculateRows();
    },
    showResult() {
      this.$api.submitGame();
      let title = this.$t('warm-up-games.congratulations');
      let description = this.$t('warm-up-games.you-achieved-level');
      description = description.toString().replace('{level}', this.level);
      let button = this.$t('common.ok');
      let action = () => this.$router.push('/warm-up/sequence');
      let persistent = true;
      this.$store.commit('showSimpleDialog', {title, description, button, action, persistent});
    }
  }
}
</script>

<style scoped>
#game {
  height: 500px;
  margin: 10px auto;
  width: 500px;
}

.row {
  width: 500px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.tile {
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
  transition-duration: 250ms;
}


@media screen and (max-width: 530px) {
  #game {
    height: 350px;
    margin: 10px auto;
    width: 350px;
  }

  .row {
    width: 350px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    justify-content: center;
    align-items: center;
  }

  .tile {
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
    transition-duration: 250ms;
  }
}
</style>
