<template>
  <div id="header-warm-up-container" class="d-flex flex-row flex-nowrap justify-space-between align-center">
    <div id="header-warm-up-title" v-if="$vuetify.breakpoint.width > 600">{{ title }}</div>
    <div id="header-warm-up-level" v-if="level >= 0">{{ $t('common.level') }}: {{ level }}</div>
    <div v-else>&nbsp;</div>
    <div id="header-warm-up-hearts" v-if="hearts >= 0">
      <v-icon v-for="i in 3" :key="i" x-large :color="hearts >= i ? 'primary' : '#a9b4c2'">mdi-heart</v-icon>
    </div>
    <div id="header-warm-up-no-hearts" v-else-if="$vuetify.breakpoint.width > 600">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'HeaderWarmUp',
  props: {
    title: {type: String, required: true},
    level: {type: Number, required: false, default: -1},
    hearts: {type: Number, required: false, default: -1}
  }
}
</script>

<style scoped>
#header-warm-up-container {
  color: #336799;
  height: 70px;
  width: 100%;
}

#header-warm-up-title {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 20px;
  width: 300px;
  white-space: nowrap;
}

#header-warm-up-level {
  font-size: 22px;
  font-weight: 500;
  margin: 20px;
  white-space: nowrap;
}

#header-warm-up-hearts {
  margin: 20px;
  text-align: right;
  width: 300px;
  white-space: nowrap;
}

#header-warm-up-no-hearts {
  color: transparent;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 20px;
  text-align: right;
  user-select: none;
  width: 300px;
  white-space: nowrap;
}
</style>
